import styled from 'styled-components';

const Header = styled.div`
  position: relative;
  max-width: 100rem;
  width: 85%;
  margin: clamp(5rem, 10vw, 10rem) auto 0;
  text-align: justify;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  gap: clamp(5rem, 13vw, 10rem);

  h1 {
    font-size: clamp(3.5rem, 9vw, 6rem);
    color: var(--green-light);
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--green-medium);
    text-transform: uppercase;
    font-weight: 500;
  }

  ul {
    position: relative;

    li {
      margin-bottom: 2.5rem;

      &:nth-last-child(even) {
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }

  section {
    margin-bottom: 2rem;

    p {
      margin-bottom: 3rem;
    }
  }

  div {
    border-top: 1px solid var(--green-light);
    padding-top: 3rem;
  }

  @media (min-width: 800px) {
    h1 {
      position: absolute;
      top: -20rem;
      right: 0;

      text-align: right;
      line-height: 100%;
    }
  }
`;

export default Header;
