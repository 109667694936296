import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Header from '../styles/Header';
import SEO from '../components/SEO';
import MediaColumn from '../components/MediaColumn';
import MediaSlider from '../components/MediaSlider';
import Modal from '../components/Modal';

const GraphicPage = ({ data }) => {
  const graphic = data.graphic.nodes[0];
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    document.querySelector('html').style.overflow = selectedImage
      ? 'hidden'
      : 'visible';
  }, [selectedImage]);

  const onModalClose = () => {
    setSelectedImage(null);
  };

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <>
      <SEO title="Grafica" />
      <Header>
        <h1>{graphic.name}</h1>
        <section>
          <p>{graphic.description}</p>
          <h3 className="subtitle">Strumenti</h3>
          <p>{graphic.skills}</p>
          <div>
            <a href="mailto:info@mabastudio.eu" className="orange">
              {graphic.subtitile}
            </a>
          </div>
        </section>
        <ul>
          {graphic.services.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </Header>
      {graphic.gallerys.map((gallery, index) => (
        <MediaColumn key={index} project={gallery} />
      ))}
      {graphic.gallerys.map((gallery, index) => (
        <MediaSlider
          key={index}
          project={gallery}
          onModalClose={onModalClose}
          handleClick={handleClick}
        />
      ))}
      {selectedImage && (
        <Modal selectedImage={selectedImage} onModalClose={onModalClose} />
      )}
    </>
  );
};

export default GraphicPage;

export const query = graphql`
  query GraphicQuery {
    graphic: allSanityPage(filter: { name: { regex: "/gra/i" } }) {
      nodes {
        name
        subtitile
        description
        skills
        services
        gallerys {
          galleryName
          images {
            image {
              asset {
                fluid(maxWidth: 600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
